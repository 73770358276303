module services {
    export module applicationcore {
        export class menuService implements interfaces.applicationcore.IMenuService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getMenuList(): ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>> {
                return <ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>>>
                    this.$resource(this.ENV.DSP_URL + "User/GetUserGTSMenu");
            }

            getGTSConnectMenuItems(): ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>> {

                return <ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>>>
                    this.$resource(this.ENV.DSP_URL + "Rights/GetGTSConnectMenuItems", {
                        parentId: "@parentId"
                    });
            }

            getGTSConnectMenuItem(rightId: number): ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>> {

                return <ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IMenu>>>
                    this.$resource(this.ENV.DSP_URL + "Rights/GetGTSConnectMenuItem", {
                        rightId: rightId
                    });
            }

        }
    }
    angular.module("app").service("menuService", services.applicationcore.menuService);
}